import React from "react";
import { Link } from "react-router-dom";
import Project from "./common/project";

import INFO from "../information/homeInfo";

import "./styles/allProjects.css"

const AllProjects = () => {
    return (
            <div className="project-section">

                <div className="project-flex">

                    <div className="projects-text">

                        <div className="projects-title">
                            {/* <Link to="/projects"> */}
                                Full-Stack Development
                            {/* </Link> */}
                        </div>


                    </div>

                    <div className="all-projects-container">
                        {INFO.full_stack.map((project, index) => (
                            <Link to={project.projectLink} key={index} className="all-projects-project">
                                <Project 
                                    title={project.title}
                                    description={project.description}
                                    participation={project.participation}
                                    languages={project.languages}
                                    githubLink={project.githubLink}
                                    deployedLink={project.deployedLink}
                                />
                            </Link>
                        ))}
                    </div>

                    <div className="projects-text">

                        <div className="projects-title">
                            {/* <Link to="/projects"> */}
                                DevOps
                            {/* </Link> */}
                        </div>

                    </div>

                    <div className="all-projects-container">
                        {INFO.devops.map((project, index) => (
                            <Link to={project.projectLink} key={index} className="all-projects-project">
                                <Project 
                                    title={project.title}
                                    description={project.description}
                                    participation={project.participation}
                                    languages={project.languages}
                                    githubLink={project.githubLink}
                                    deployedLink={project.deployedLink}
                                />
                            </Link>
                        ))}
                    </div>



                    <div className="projects-text">

                        <div className="projects-title">
                            {/* <Link to="/projects"> */}
                                Automation & Scripting
                            {/* </Link> */}
                        </div>

                    </div>

                    <div className="all-projects-container">
                        {INFO.automation.map((project, index) => (
                            <Link to={project.projectLink} key={index} className="all-projects-project">
                                <Project 
                                    title={project.title}
                                    description={project.description}
                                    participation={project.participation}
                                    languages={project.languages}
                                    githubLink={project.githubLink}
                                    deployedLink={project.deployedLink}
                                />
                            </Link>
                        ))}
                    </div>

                </div>
                
            </div>
    )
}

export default AllProjects;