import React from "react";
import { Link } from "react-router-dom";
import "../styles/project.css";

const Project = (props) => {
    const { title, description, participation, languages, githubLink, deployedLink, projectLink } = props;

    return (
        <React.Fragment>
            <div className="project">
                <div className="project-text">
                    
                    <div className="project-left">
                        <div className="project-title">
                            {title}
                        </div>

                        <div className="project-language">
                            {languages}
                        </div>
                    </div>

                    <div className="project-right">
                        <div className="project-description">
                            {description}
                        </div>
                    
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default Project;