// This is where I am storing the text information for the Home Page. I will be calling on this object frequently thought pages/home.jsx

const INFO = {
    intro: {
            name: "Aaron Sanchez",
            bio: `Hello, my name is Aaron!

                I'm a Full-Stack Developer based in Austin, TX.
          
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla placerat odio id urna ullamcorper luctus. Mauris et lacus eget arcu cursus vestibulum sed nec tortor. Nulla tortor velit, molestie et vulputate quis, imperdiet id elit. Proin at commodo est. In nec tortor aliquet, finibus mi et, eleifend nisl.`,
            headshot: '../assets/headshot.jpg'
    },
    full_stack: [
        {
            title: "grocery_grid",
            description: "Meal planning application that allows users to store meals, recipes, and ingredients, and generate a grocery list based on their meal plan",
            participation: "",
            languages: "MERN Stack",       
            githubLink: "https://github.com/aarosan/grocery_grid",
            deployedLink: "https://grocerygrid.netlify.app",
            projectLink: "https://grocerygrid.netlify.app",
        },
        // {
        //     title: "above par",
        //     description: "Mini golf scorecard application, including user authentication and course creation",
        //     participation: "",
        //     languages: "MERN Stack",       
        //     githubLink: "",
        //     deployedLink: "",
        //     projectLink: "",
        // },
        // {
        //     title: "e-commerce",
        //     description: "Mock e-commerce site, complete with user authentication and shopping cart functionality",
        //     participation: "",
        //     languages: "MERN Stack",       
        //     githubLink: "",
        //     deployedLink: "",
        //     projectLink: "",
        // }
    ],
    devops: [
        {
            title: "CI/CD Pipeline",
            description: "Continuous Integration and Continuous Deployment pipeline for a MERN stack project",
            participation: "",
            languages: "Jenkins | Docker | AWS",       
            githubLink: "",
            deployedLink: "",
            projectLink: "https://docs.google.com/document/d/1HXeQrW-Kb5vDJ2YX9qYVKSIBbCJCnxYSkBVig7QLZBw/edit?usp=sharing",
        }
    ],
    automation: [
        {
            title: "MERN/MEVN/MEAN Stack Setup Automation",
            description: "Command line tool to automate the setup of a MERN, MEVN, or MEAN stack project",
            participation: "",
            languages: "Python",       
            githubLink: "https://github.com/aarosan/frontend-stack-automation",
            deployedLink: "",
            projectLink: "https://github.com/aarosan/frontend-stack-automation",
        }
    ],
    
    contact: {
        title: "Contact Me!",
        githubProfle: "",
        linkedInProfile: "",
        email: "aaron.ross.sanchez@gmail.com"
    },

    about: {
        bio: "Hello! My name is Aaron and I'm Full Stack Developer based in Austin, TX. With a focus on web accessibility, APIs, and backend services, I enjoy creating full-stack applications to address day-to-day problems. Outside of coding, I love to paint, garden, hike the greenbelt, and end the day with a good video game.",
        languages: "HTML | CSS | Bootstrap | Tailwind | JavaScript | Node.js | Express.js | MySQL | MongoDB | React | Apollo GraphQL | Python",
        certifications: "CKA | AWS Cloud Practitioner | Microsoft Azure Fundamentals"
    }


}

export default INFO;